<template>
  <div>
      <v-dialog v-model="dialog" persistent width="801px" style="box-shadow: none;">
      <v-card style="border-radius: 10px; " >
        <v-container v-if="errorMessageShow" style="margin: 0px !important;padding: 0% !important;">
          <div class="dialogContainer" style="display:flex !important; justify-content:space-between;">
            <div class="flex1">
            <span>
            <v-icon class="rounded-xl" size="28px" color="#FF7070" style=" height: 36.67px; ">mdi-information-outline</v-icon>
            <span
            style="
              color: #FF7070;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: 0.02em;
            "
            >
         
            Error info
          </span>
        </span>
        </div>
        <div class="flex2">  
        <span style=" font-size: 15px; font-weight: 500;  padding-right: 30px;">
            <template v-if="error_uuid" >
            Unique id: {{ error_uuid }} 
            <v-tooltip bottom v-model="tooltip" color="grey" max-width="100px"  >
            <template v-slot:activator="{ attrs }"> 
              <v-btn  elevation="0" class="copybtn" v-bind="attrs" @click="copyUuid()">  Copy <img style=" width:100%; margin-left: 7px;" src="../assets/CopyIcon.png" /></v-btn>
            </template>
            <span style="color:white !important">
              Copied
            </span>
            </v-tooltip>
        </template>
          
        </span>
            
        </div>
          </div>
        </v-container>
        <v-container 
        v-else
        style="font-family: 'Poppins';
        font-style: normal;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        padding: 15px 0px !important;
        padding-left: 45px !important;
        text-align: left;
        letter-spacing: 0.02em;">
        &nbsp;
        Information
        </v-container>
        <v-divider  style="margin:0px 30px !important;" />
         <v-card-text v-if="errorMessageShow" class="contentCard" style="padding: 0px 30px !important; ">
        <div class="grid-1">
          <h1 class="errorHeading">Ooops...</h1>
          <h2 v-if="error_code" class="errorCode">Error code: {{ error_code }} </h2>
          <p class="errorDetails" >
          <template v-if="errorMessage">
            Message: {{ errorMessage }}
          </template>
          </p>  
          <p class="errorDetails">          
          <template v-if="errorMessageInMM && isMMSecondarySupportLang">
            မက်ဆေ့ချ်- {{ errorMessageInMM }}
          </template>
          </p>
        </div>
          <div class="grid-2">
          <v-img class="cardimage"
          :src="require('../assets/ErrorImage.png')"
          contain/>
          </div>
          <div class="grid-3">
            <v-btn
            elevation="0"
            style="background-color: #BBBCBD !important;
            text-transform: unset !important;
            color: #ffffff;
            font-family: 'Poppins';
            font-weight: 600;
            width: 94px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            padding: 16px; 
            border-radius: 6px;           
            "
            @click="clickOk()"
            >Close </v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else class="contentCard" style="padding: 0px 30px !important; ">
        <div class="grid-1">
          <h1 class="errorHeading">Ooops...</h1>
          <p class="errorDetails" v-if="text">Message: {{ text }}
         
          </p>  
          
        </div>
          <div class="grid-2">
          <v-img class="cardimage"
          :src="require('../assets/ErrorImage.png')"
          contain/>
          </div>
          <div class="grid-3">
            <v-btn
            elevation="0"
            style="background-color: #BBBCBD !important;
            text-transform: unset !important;
            color: #ffffff;
            font-family: 'Poppins';
            font-weight: 600;
            width: 94px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            padding: 16px; 
            border-radius: 6px;"
            @click="clickOk()"
            >Close </v-btn>
          </div>
        </v-card-text>   
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {getFromLocalStorage} from "../store/localStorageInterface"
export default {
  props: ["displayDialog", "messageObject"],
  data() {
    return {
      isMMSecondarySupportLang: false,
      tooltip:false,
      dialog: false,
      text: "",
      errorMessageShow: false,
      error_code: "",
      errorMessage: "",
      errorMessageInMM: "",
      error_uuid: "",
      errorDisplayMessage: false,
    };
  },
  beforeDestroy(){
    clearTimeout(this.timer)
  },
  methods: {
    async copyUuid(){
      try {
      await navigator.clipboard.writeText(this.error_uuid);
      this.tooltip=true;
      this.timer= setTimeout(()=>this.tooltip=false,1000)
    } catch(error) {
      alert('Cannot copy');
    }
    },
    isExternalEmbedPostMessage() {
      // checking for the external embeded dialog
      let isExternalEmbedDialog = getFromLocalStorage("authType")
      if (isExternalEmbedDialog === 'externalEmbed') {
        let externalEmbedUrl = getFromLocalStorage("externalEmbedUrl");
        // reporting to the client on successfull add record
        let errorDetails = {
          errorCode: this.error_code,
          errorUuid: this.error_uuid,
          errorMessage: this.errorMessage
        }
        window.top.postMessage({ messageType: 'error', errorDetails}, externalEmbedUrl);
      }
    },
    clickOk() {
      this.dialog = false;
      if (this.errorMessageShow) {
        this.errorDisplayMessage = this.errorMessageShow;
      }
      this.errorMessageShow = false;
      this.isExternalEmbedPostMessage();
      this.$emit("closeMessageDialog", {
        errorDisplayMessage: this.errorDisplayMessage,
        flag: false,
      });
    },
    generateErrorMessage(errorCode) {
      switch (errorCode) {
        case 1001:
          this.errorMessage =
            "Error in creating application. Please try again with valid definitions";
          break;
        case 1002:
          this.errorMessage =
            "Error in creating application. Please try again with valid definitions";
          break;
        case 1003:
          this.errorMessage =
            "Error updating application. Please try again with valid definitions.";
          break;
        case 1004:
          this.errorMessage =
            "Error updating application. Please try again with valid definitions.";
          break;
        case 1005:
          this.errorMessage =
            "Error viewing application details. Please try again.";
          this.errorMessageInMM =
            "APPLICATION ကြည့်ရာတွင် System မှအခက်အခဲရှိနေပါသည်။ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1006:
          this.errorMessage =
            "Error viewing application details. Please try again.";
          this.errorMessageInMM =
            "APPLICATION ကြည့်ရာတွင် System မှအခက်အခဲရှိနေပါသည်။ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1071:
          this.errorMessage = "Insufficient Permission or Unauthorized.";
          this.errorMessageInMM = "ခွင့်ပြုချက်အလုံအလောက်မရှိခြင်း (သို့မဟုတ်) ခွင့်ပြုထားခြင်းမရှိပါ။";
          break;
        case 1072:
          this.errorMessage =
            "Insufficient Permissions to perform this operation.";
          this.errorMessageInMM =
            "လုပ်ဆောင်ရန် ခွင့်ပြုချက်အလုံအလောက်မရှိခြင်း။";
          break;
        case 1007:
          this.errorMessage =
            "Error getting application defintion. Please try again.";
          this.errorMessageInMM =
            "APPLICATION ကြည့်ရာတွင် System မှအခက်အခဲရှိနေပါသည်။ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1008:
          this.errorMessage =
            "Error getting application defintion. Please try again.";
          this.errorMessageInMM =
            "APPLICATION ကြည့်ရာတွင် System မှအခက်အခဲရှိနေပါသည်။ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1009:
          this.errorMessage = "Error in getting user roles. Please try again.";
          this.errorMessageInMM = "User roles တွင် အမှားယွင်းရှိနေပါသည်။ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1010:
          this.errorMessage = "Error in getting user roles. Please try again.";
          this.errorMessageInMM = "User roles တွင် အမှားယွင်းရှိနေပါသည်။ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1011:
          this.errorMessage =
            "Error getting application defintion. Please try again.";
          break;
        case 1073:
          this.errorMessage = "Unauthorized. Please login and try again.";
          this.errorMessageInMM = "ခွင့်ပြုချက်မလုံလောက်ပါသဖြင့် Login ထပ်မံဝင်ရောက်ပါ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1074:
          this.errorMessage = "Unauthorized. Please login and try again.";
          this.errorMessageInMM = "ခွင့်ပြုချက်မလုံလောက်ပါသဖြင့် Login ထပ်မံဝင်ရောက်ပါ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1075:
          this.errorMessage = "Unauthorized. Please login and try again.";
          this.errorMessageInMM = "ခွင့်ပြုချက်မလုံလောက်ပါသဖြင့် Login ထပ်မံဝင်ရောက်ပါ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ။";
          break;
        case 1076:
          this.errorMessage =
            "Unauthorized. Invalid authentication type. Please login and try again.";
          this.errorMessageInMM =
            "ခွင့်ပြုချက်အထောက်ထား မမှန်ကန်ပါ ပြန်လည်စစ်ဆေးပေးပါ။";
          break;
        case 1077:
          this.errorMessage = "Error in authentication user. Please try again.";
          this.errorMessageInMM = "Login အချက်လက်မှားယွင်းနေပါသည် ပြန်လည်စစ်ဆေးပါ။";
          break;
        case 1078:
          this.errorMessage = "Error in authentication user. Please try again.";
          this.errorMessageInMM = "Login အချက်လက်မှားယွင်းနေပါသည် ပြန်လည်စစ်ဆေးပါ။";
          break;
        case 1201:
          this.errorMessage = "Unexpected Error occured while fetching notes.";
          break;
        case 1216:
          this.errorMessage = "Error occured while identifing user role.";
          break;
        case 1217:
          this.errorMessage = "User doesnt have the rights to perform this operation";
          break;
        case 1212:
          this.errorMessage = "Record does not exist";
          break;
        case 1215:
          this.errorMessage = "Details of the viewing appliction is not found";
          break;
        case 1214:
          this.errorMessage = "Details of the viewing appliction is not found";
          break;
        case 1218:
          this.errorMessage = "User doesnt have the rights to perform this operation";
          break;
        case 1213:
          this.errorMessage = "Unexpected Error Occured while validaitng your request";
          break;
        case 1222:
          this.errorMessage = "Unauthorized user does not exist";
          break;
        case 1221:
          this.errorMessage = "Unexpected Error Occured while validaitng your request";
          break;
        case 1211:
          this.errorMessage = "Unexpected Error Occured while fetching all notes";
          break;
        case 2000:
          this.errorMessage =
            "Error in adding View Definition. Please Try Again.";
          break;
        case 2001:
          this.errorMessage =
            "Error in adding View Definition. Please Try Again.";
          break;
        case 2002:
          this.errorMessage =
            "Error in getting all view details. PLease Try Again";
          break;
        case 2003:
          this.errorMessage =
            "Error in getting all view details. PLease Try Again";
          break;
        case 2004:
          this.errorMessage =
            "Error in updating view definition. Please Try Again";
          break;
        case 2006:
          this.errorMessage =
            "Error in fetching View Application details. Please Try Again";
          break;
        case 2008:
          this.errorMessage =
            "Error in updating WorkFlow definition. Please Try Again";
          break;
        case 2010:
          this.errorMessage =
            "Error in fetching WorkFlow definition. Please Try Again";
          break;
        case 2012:
          this.errorMessage =
            "Error in deleting WorkFlow Definition. Please Try Again";
          break;
        case 2005:
          this.errorMessage =
            "Error in updating view definition. Please Try Again";
          break;
        case 2007:
          this.errorMessage =
            "Error in fetching View Application details. Please Try Again";
          break;
        case 2009:
          this.errorMessage =
            "Error in updating WorkFlow definition. Please Try Again";
          break;
        case 2011:
          this.errorMessage =
            "Error in fetching WorkFlow definition. Please Try Again";
          break;
        case 2013:
          this.errorMessage =
            "Error in deleting WorkFlow Definition. Please Try Again";
          break;
        case 3000:
          this.errorMessage = "Invalid Credentials";
          this.errorMessageInMM = "Login အချက်လက်မှားယွင်းနေပါသည် ပြန်လည်စစ်ဆေးပါ။";
          break;
        case 3001:
          this.errorMessage = "Invalid FromEmailAddress";
          this.errorMessageInMM = "ပေးပို့သူ အီးမေးလ် လိပ်စာမှားယွင်းနေပါသည်။ (မှ)";
          break;
        case 3002:
          this.errorMessage = "Invalid ToEmailAddress";
          this.errorMessageInMM = "လက်ခံသူ အီးမေးလ် လိပ်စာမှားယွင်းနေပါသည်။ (သို့)";
          break;
        case 3003:
          this.errorMessage = "Invalid Subject";
          this.errorMessageInMM = "အကြောင်းအရာမှားယွင်းနေပါသည်။";
          break;
        case 3004:
          this.errorMessage = "Invalid BodyTemplate";
          this.errorMessage = "အီးမေးလ်စာပိုဒ်မှားယွင်းထည့်သွင်းထားပါသည်။";
          break;
        case 3005:
          this.errorMessage =
            "Error in sending email notification. Please Try Again";
          break;
        case 3006:
          this.errorMessage =
            "Error in sending email notification. Please Try Again";
          break;
        case 3007:
          this.errorMessage =
            "Error in sending email notification. Please Try Again";
          break;
        case 3008:
          this.errorMessage = "ApplicationId is not defined. Please Try Again";
          break;
        case 4000:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4001:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4002:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4012:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4013:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4014:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4020:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4021:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4022:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4024:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4025:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4026:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 5006:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 5007:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 5008:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 5013:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 5014:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 5015:
          this.errorMessage = "You are not Authorized to do this operation";
          break;
        case 4003:
          this.errorMessage =
            "Error in getting screen definition. Please Try Again";
          break;
        case 4004:
          this.errorMessage =
            "Invalid operand for computed type. Please check the input";
          break;
        case 4005:
          this.errorMessage = "Invalid Entity Type. Please Try Again";
          break;
        case 4006:
          this.errorMessage = this.$props.messageObject.errorMessage + " already exists in the system";
          break;
        case 4007:
          this.errorMessage = "Error in adding Record. Please Try Again";
          break;
        case 4011:
          this.errorMessage = "Error in adding Record. Please Try Again";
          break;
        case 4008:
          this.errorMessage =
            "Error in updating workflow status. Please Try Again";
          break;
        case 4009:
          this.errorMessage = "Required fields are missing. Please check and try again."
          break;
        case 4010:
          this.errorMessage =
            "Error in getting user role type. Please Try Again";
          break;
        case 4018:
          this.errorMessage =
            "Error in getting user role type. Please Try Again";
          break;
        case 4015:
          this.errorMessage = "Error in deleting record. Please Try Again";
          break;
        case 4017:
          this.errorMessage = "Error in deleting record. Please Try Again";
          break;
        case 4019:
          this.errorMessage = "Error in deleting record. Please Try Again";
          break;
        case 4030:
          this.errorMessage = "Error in deleting record. Please Try Again";
          break;
        case 4016:
          this.errorMessage = "Cannot Delete this record";
          break;
        case 4023:
          this.errorMessage = "Error in editing record. Please Try Again";
          break;
        case 4027:
          this.errorMessage = "Error in editing record. Please Try Again";
          break;
        case 4029:
          this.errorMessage = "Error in editing record. Please Try Again";
          break;
        case 4031:
          this.errorMessage = "Error in editing record. Please Try Again";
          break;
        case 4032:
          this.errorMessage = "Error in editing record. Please Try Again";
          break;
        case 4034:
          this.errorMessage = "Error in editing record. Please Try Again";
          break;
        case 4028:
          this.errorMessage = "Please check the input data";
          this.errorMessageInMM = "ဖြည့်သွင်းသော data ကိုစစ်ဆေးပါ။";
          break;
        case 4033:
          this.errorMessage = this.$props.messageObject.errorMessage;
          break;
        case 4035:
          this.errorMessage = "Error in inserting comment. Please Try Again";
          break;
        case 4036:
          this.errorMessage = "Error in inserting comment. Please Try Again";
          break;
        case 4037:
        case 4038:
          this.errorMessage = "Error in inserting comment. Please Try Again";
          break;
        case 4039:
        case 4040:
          this.errorMessage = "Error in updating Role Group Definitions. Please Try Again";
          break;
        case 5000:
          this.errorMessage = "Error in getting Mime Types. Please Try Again";
          break;
        case 5001:
          this.errorMessage =
            "Error in Getting Presigned Url write. Please Try Again";
          break;
        case 5002:
          this.errorMessage = "Restricted File type";
          this.errorMessageInMM = "သတ်မှတ်ထားသော ဖိုင်အမျိုးအစား မဟုတ်ပါ။";
          break;
        case 5003:
          this.errorMessage =
            "Error in Getting Presigned Url write. Please Try Again";
          break;
        case 5010:
          this.errorMessage = "Restricted File type";
          this.errorMessageInMM = "သတ်မှတ်ထားသော ဖိုင်အမျိုးအစား မဟုတ်ပါ။";
          break;
        case 5004:
          this.errorMessage =
            "Error in Getting Presigned Url Read. Please Try Again";
          break;
        case 5005:
          this.errorMessage =
            "Error in getting user role type.Please Try Again";
          break;
        case 5012:
          this.errorMessage =
            "Error in getting user role type.Please Try Again";
          break;
        case 5009:
          this.errorMessage =
            "Error in getting presigned post url. Please Try Again";
          break;
        case 5011:
          this.errorMessage =
            "Error in getting presigned post url. Please Try Again";
          break;
        case 5017:
          this.errorMessage =
            "Error in getting presigned post url. Please Try Again";
          break;
        case 5018:
          this.errorMessage =
            "File size exceeded 10 Mb. Please select a file under the size limit";
          this.errorMessageInMM =
            "ဖိုင်အရွယ်အစားသည် 10 Mb ထက်မကျော်ရပါ။";
          break;
        case 5016:
          this.errorMessage = "Error in adding csv job. Please Try Again";
          break;
        case 6000:
          this.errorMessage =
            "Error in getting list of applications. Please Try Again";
          break;
        case 6002:
          this.errorMessage =
            "Computation values Mismatch. Please Try Again";
          break;
        case 7000:
          this.errorMessage = "Error in adding Role Details. Please Try Again";
          break;
        case 7002:
          this.errorMessage = "Error in getting user list. Please Try Again";
          break;
        case 7004:
          this.errorMessage = "Error in getting user group. Please Try Again";
          break;
        case 7006:
          this.errorMessage =
            "Error in fetching User Role Details. Please Try Again";
          break;
        case 7100:
          this.errorMessage =
            "Aggregate field operation type is Invalid";
          break;
        case 7101:
          this.errorMessage =
            "Aggregate Value Mismatch. Please Check Again";
          break;
        case 7103:
        case 7105:
        case 7107:
            this.errorMessage = "Unable to calculate the sum due to invalid or incomplete data";
            break;
        case 7104:
        case 7106:
        case 7108:
            this.errorMessage = "Unable to calculate the average due to invalid or incomplete data";
            break;
        case 6001:
          this.errorMessage =
            "Error in getting list of applications. Please Try Again";
          break;
        case 7001:
          this.errorMessage = "Error in adding Role Details. Please Try Again";
          break;
        case 7003:
          this.errorMessage = "Error in getting user list. Please Try Again";
          break;
        case 7005:
          this.errorMessage = "Error in getting user group. Please Try Again";
          break;
        case 7007:
          this.errorMessage =
            "Error in fetching User Role Details. Please Try Again";
          break;
        case 7008:
          this.errorMessage =
            "Error in updating User Role Details. Please Try Again";
          break;
        case 7010:
          this.errorMessage =
            "Error in updating User Role Details. Please Try Again";
          break;
        case 7009:
          this.errorMessage =
            "Error in updating User Role Details. Please Try Again";
          break;
        case 7011:
          this.errorMessage =
            "Error in fetching data of User from Rolegroup for deleting. Please Try Again";
          break;
        case 7012:
          this.errorMessage =
            "Error in deleting User from group. Please Try Again";
          break;
        case 7013:
          this.errorMessage = "Error in deleting ScopeEntry. Please Try Again";
          break;
        case 7014:
          this.errorMessage = "Error in deleting User role. Please Try Again";
          break;
        case 7015:
          this.errorMessage = "Error in deleting User role. Please Try Again";
          break;
        case 7016:
          this.errorMessage =
            "Error in deleting User from Rolegroup. Please Try Again";
          break;
        case 7017:
          this.errorMessage =
            "Error in adding User to Rolegroup. Please Try Again";
          break;
        case 7018:
        case 7019:
          this.errorMessage =
            "Error in Viewing Role Group Definiton. Please Try again";
          break;
        case 1800:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1801:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1804:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1805:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1810:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1802:
          this.errorMessage = "Error in verifying user. No auth type provided";
          break;
        case 1806:
          this.errorMessage = "Error in verifying user. No auth type provided";
          break;
        case 1803:
          this.errorMessage = "Error in verifying user";
          break;
        case 1812:
          this.errorMessage = "Error in verifying user";
          break;
        case 1807:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1808:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1809:
          this.errorMessage = "Error in getting user roles";
          break;
        case 1811:
          this.errorMessage = "Error in verifying user. Forbidden";
          break;
        case 1813:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1814:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1815:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1816:
          this.errorMessage = "Error in verifying user. Unauthorized";
          break;
        case 1817:
          this.errorMessage = "Error in obtaining token";
          break;
        case 1901:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1900:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1902:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1903:
          this.errorMessage = "Error in getting application Details";
          break;
        case 1904:
          this.errorMessage = "Error in updating workflow status";
          break;
        case 1905:
          this.errorMessage = "Error in updating workflow status";
          break;
        case 1906:
          this.errorMessage = "User doesn't have rights to perform this operation";
          break;
        case 1907:
          this.errorMessage = "User doesn't have rights to perform this operation";
          break;
        case 1908:
          this.errorMessage = "Error in updating workflow status";
          break;
        case 1910:
          this.errorMessage = this.$props.messageObject.errorMessage;
          break;
        case 1912:
          this.errorMessage = "Error in updating workflow status";
          break;
        case 1909:
          this.errorMessage =
            "Error in updating workflow status. Failed processing events";
          break;
        case 1911:
          this.errorMessage = this.$props.messageObject.errorMessage;
          break;
        case 1300:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1301:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1302:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1311:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1312:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1313:
          this.errorMessage =
            "User doesn't have rights to perform this operation";
          break;
        case 1303:
          this.errorMessage = "Error in getting view Definition";
          break;
        case 1304:
          this.errorMessage = "Error in getting view Definition";
          break;
        case 1305:
          this.errorMessage = "Error in getting scope display Logic";
          break;
        case 1306:
          this.errorMessage = "Invalid Display Data";
          break;
        case 1307:
          this.errorMessage = "Default Sort is not available";
          break;
        case 1310:
          this.errorMessage = "Error in getting workflow Data";
          break;
        case 1308:
          this.errorMessage = "Error in getting workflow Data";
          break;
        case 1309:
          this.errorMessage = "Get Role Type is unsuccessful";
          break;
        case 1314:
          this.errorMessage = "Error in getting end stage";
          break;
        case 1315:
          this.errorMessage = "Error in getting Maker Checker Pull Condition";
          break;
        case 1316:
          this.errorMessage = "Error in Deleting Record";
          break;
        case 1317:
          this.errorMessage = "Error in inserting record";
          break;
        case 1318:
          this.errorMessage = "Error in Pull Records";
          break;
        case 1319:
          this.errorMessage = "Error in getting Records by date";
          break;
        case 1320:
          this.errorMessage = "Unable to run api";
          break;
        case 1321:
          this.errorMessage = "Get Screen definition Failed. Please Try Again";
          break;
        case 1322:
          this.errorMessage =
            "Displaying Master Value Failed. Please Try Again";
          break;
        case 1400:
          this.errorMessage = "Error in adding stage Details";
          break;
        case 1403:
          this.errorMessage = "Error in getting all workflows";
          break;
        case 1405:
          this.errorMessage = "Error in getting all stages";
          break;
        case 1407:
          this.errorMessage = "Error in Updating Workflow details";
          break;
        case 1402:
          this.errorMessage = "Error in adding stage Details";
          break;
        case 1401:
          this.errorMessage = "Error in adding stage Details";
          break;
        case 1404:
          this.errorMessage = "Error in getting all workflows";
          break;
        case 1406:
          this.errorMessage = "Error in getting all stages";
          break;
        case 1408:
          this.errorMessage = "Error in Updating Workflow details";
          break;
        case 1409:
          this.errorMessage = "Error in getting User role group";
          break;
        case 1410:
          this.errorMessage = "Error in getting User role group and details";
          break;
        case 1411:
          this.errorMessage = "Error in getting all user group list";
          break;
        case 1601:
          this.errorMessage = "Error uploading files. Request expired/invalid. Please reselect the file and try again";
          this.errorMessageInMM = "အချိန် ကြာမြင့်သောကြောင့် ဖိုင်တင်သွင်းခြင်းမအောင်မြင်ပါ။ Application ကို Login ပြန်လည်ဝင်ရောက်ပြီး ထပ်မံကြိုးစားကြည့်ပါ။";
          break;
        case 1602:
          this.errorMessage = "Error uploading files. Request expired/invalid. Please reselect the file and try again";
          this.errorMessageInMM = "အချိန် ကြာမြင့်သောကြောင့် ဖိုင်တင်သွင်းခြင်းမအောင်မြင်ပါ။ Application ကို Login ပြန်လည်ဝင်ရောက်ပြီး ထပ်မံကြိုးစားကြည့်ပါ။n";
          break;
        case 1603:
          this.errorMessage = "Error uploading files. Request expired/invalid. Please reselect the file and try again";
          this.errorMessageInMM = "အချိန် ကြာမြင့်သောကြောင့် ဖိုင်တင်သွင်းခြင်းမအောင်မြင်ပါ။ Application ကို Login ပြန်လည်ဝင်ရောက်ပြီး ထပ်မံကြိုးစားကြည့်ပါ။";
          break;
        case 2101:
          this.errorMessage = "Error in getting view Definition";
          break;
        case 2104:
          this.errorMessage = "Error Occured while validating user";
          break;
        case 2105:
          this.errorMessage = "Error Occured while verifying user";
          break;
        case 2100:
          this.errorMessage = "Error in getting User role group and details";
          break;
        case 2106:
          this.errorMessage ="User doesn't have rights to perform this operation";
          break;  
        case 2107:
          this.errorMessage = "You are not Authorized to do this operation";
          break;   
        case 2301:
          this.errorMessage = "Error Occured While fetching the definitions";
          break;   
        case 2302:
          this.errorMessage = "Definitions are not found for the application";
          break;   
        case 2305:
          this.errorMessage = "Error occured while fetching the details required";
          break;     
        case 2303:
          this.errorMessage = "Error occured while fetching the details required";
          break;   
        case 2209:
          this.errorMessage = "Fields required to validate user is missing";
          break;   
        case 2201:
          this.errorMessage = "Error Occured while validating user";
          break;   
        case 2203:
          this.errorMessage = "Unauthorized user";
          break;   
        case 2202:
          this.errorMessage = "Unauthorized user";
          break;   
        case 2204:
          this.errorMessage = "User Access Expired";
          break;   
        case 2304:
          this.errorMessage = "User Access Expired";
          break;   
        case 2205:
          this.errorMessage = "Unauthorized user";
          break;   
        case 2310:
          this.errorMessage = "Unauthorized user";
          break;   
        case 2206:
          this.errorMessage = "Error occured while verifying the user";
          break;   
        case 2306:
          this.errorMessage = "Error occured while verifying the user";
          break;   
        case 2207:
          this.errorMessage = "Error occured while verifying the user";
          break;   
        case 2307:
          this.errorMessage = "Error occured while verifying the user";
          break;   
        case 2309:
          this.errorMessage = "Unauthorized user";
          break;
        case 7094:
          this.errorMessage = "Error occured in fetching more Notifications";
          break;   
        case 7104:
          this.errorMessage = "Error occured in fetching more Notifications";
          break;
        case 4056:
          this.errorMessage = "Existing records are mapped to the current value. Deletion aborted";
          break;
        case 4057:
          this.errorMessage = "Existing records are mapped to the current value. Cannot be edited";
          break;
        default:
          this.errorMessage = "Please Try Again. Sorry for the inconvenience";
          break;
      }
    },
  },
  mounted() {
    let secondarySupportLanguageDetails = getFromLocalStorage('secondarySupportLanguage');
    if (secondarySupportLanguageDetails && secondarySupportLanguageDetails.secondarySupportLanguage === 'Burmese' && secondarySupportLanguageDetails.applicationId == this.$route.query.applicationId) {
      this.isMMSecondarySupportLang = true;
    }
    this.dialog = this.$props.displayDialog;
    var messageObject = this.$props.messageObject;
    if (messageObject.errorMessageShow) {
      this.errorMessageShow = messageObject.errorMessageShow;
      this.error_code = messageObject.error_code;
      this.error_uuid = messageObject.error_uuid;
      this.generateErrorMessage(this.error_code);
    } else {
      this.text = messageObject.displayMessage;
    }
  },
};
</script>
<style scoped>

/*error Container styles */
.dialogContainer{
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 30px;
  flex-direction: row !important;
}

.errorHeading{
  margin-top: 18px;
  font-size: 42px;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: 0.02em;
}
.copybtn{
  background-color: #FF5B66 !important;
  text-transform: unset !important;
  color: #ffffff !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  margin-left: 5px;
  letter-spacing: 0.02em;
  padding: 16px; 
  border-radius: 3px;
}
.contentCard{
  font-family: 'Poppins';
  display: grid !important;
  grid-template-columns: repeat(2,1fr) !important;
  grid-auto-rows: minmax(100px,auto) !important;
  margin: 0px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  text-align: left;
}
.grid-1{
  padding-bottom: 25px;
  grid-column: 1;
  grid-row: 1/2;
}
.grid-2{
  grid-column: 2;
  grid-row: 1/2;
  margin: 0px !important;
}
.grid-3{
  grid-column: 1;
  grid-row: 1/2;
  margin-top: 300px;
  margin-bottom: 40px;
}
.grid-2 .cardimage{
  height: 270px;
  width: 381px;
}
.errorCode{
font-size: 33px;
font-weight: 400;
line-height: 60px;
letter-spacing: 0.02em;
margin-top: 10px;
text-align: left;
}
.errorDetails{
font-size: 15px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0.02em;
text-transform: unset !important;
margin-top: 10px;
}

@media (max-width:700px) {
  .dialogContainer{
    flex-direction: column !important;
    margin-right: 10px !important;

  }
  .contentCard{
    grid-template-columns: repeat(1,1fr) !important;
  grid-auto-rows: minmax(100px,auto) !important;
  }
.grid-1{
  grid-column: 1;
  grid-row: 1/2;
  padding-bottom: 0px !important;
}
.grid-2{
  grid-column: 1;
  grid-row: 2/3;
  margin-bottom: 20px !important;
}
.grid-3{
  grid-column: 1;
  grid-row: 1/2;
  margin-top: 245px !important;
  margin-bottom: 0px !important;
}
  .grid-2 .cardimage{
  height: 230px;
  width: 285px;
  }
}
@media (max-width:900px) {
 
  .grid-3{
  grid-column: 1;
  grid-row: 1/2;
  margin-top: 245px;
  margin-bottom: 65px;
}
}


</style>